import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "point-of-sale-pos-integrations"
    }}>{`Point of Sale (POS) integrations`}</h1>
    <p>{`One of the main integration use cases is to send total daily revenue into Planday, which enable managers to compare labour costs with the imported revenue. In Planday we automatically calculate the payroll percentage (labour cost vs. revenue) based on these numbers and this can be compared to a defined budget set in Planday.`}</p>
    <p>{`Revenue is linked to revenue units in Planday. Every revenue unit is capable of storing one revenue record per day and is tied to a Planday department. There can be multiple revenue units in a department, reflecting where the revenue was generated. Revenue units can be managed by users inside Planday or via our `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/revenue#tag/RevenueUnit"
      }}>{`API`}</a>{`. Learn more about revenue and revenue units in our `}<a parentName="p" {...{
        "href": "https://help.planday.com/en/articles/3807690-revenue-in-planday"
      }}>{`Help Centre article`}</a>{`.
A typical example of how revenue units are used and structured can be described with the example below.`}</p>
    <h4 {...{
      "id": "example"
    }}>{`Example`}</h4>
    <p>{`Business X has two restaurants and in each restaurant they generate revenue in the bar and in the restaurant. This could look like following:`}</p>
    <p>{`Business X has one Planday portal:`}</p>
    <ul>
      <li parentName="ul">{`Department A (Restaurant 1)`}
        <ul parentName="li">
          <li parentName="ul">{`Revenue unit 1A (Restaurant 1 - Bar)`}</li>
          <li parentName="ul">{`Revenue unit 2A (Restaurant 1 - Restaurant)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Department B (Restaurant 2)`}
        <ul parentName="li">
          <li parentName="ul">{`Revenue unit 1B (Restaurant 2 - Bar)`}</li>
          <li parentName="ul">{`Revenue unit 2B (Restaurant 2 - Restaurant)`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "entity-mapping"
    }}>{`Entity mapping`}</h3>
    <p>{`First, you will need to allow users to map entities in your system with Planday departments and revenue units. `}</p>
    <p>{`For instance, if your POS system is based on sales areas with registers, you should allow users to map sales areas to departments. Subsequently, users should have the option to map registers to existing revenue units or create a new one corresponding to a chosen register.`}</p>
    <ul>
      <li parentName="ul">{`sales areas in the example could be equivalent to shop floors, outlets or shops in your system`}</li>
      <li parentName="ul">{`registers could represent POS devices or cash desks`}</li>
    </ul>
    <p>{`Users have the option to view detailed Revenue reports in Planday or see a breakdown of daily revenue values for each unit in Schedule, therefore it is recommended that POS devices in the 3rd party system are mapped to individual units instead of cummulating revenue from several devices into one revenue unit.`}</p>
    <h3 {...{
      "id": "adding-revenue"
    }}>{`Adding revenue`}</h3>
    <p>{`Once mappings have been set up, you can use the `}<inlineCode parentName="p">{`POST /revenue/v{version}/revenueunits`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/revenue#tag/Revenue/paths/~1revenue~1v%7Bversion%7D~1revenue/post"
      }}>{`endpoint`}</a>{` to set the daily revenue values for individual units in Planday.
Here you have to specify the value, the revenue unit ID, date and optionally a comment. We recommend to include a comment similar to "Imported from {your system name} on {dateTime of import}".`}</p>
    <p>{`You can use the same endpoint to update revenue if you wish to import revenue for the same day later again.`}</p>
    <p>{`It is only possible to import revenue on a "business day" in Schedule. In practice, this means that there must be at least one shift on the given day. If no shifts are scheduled for a day, Planday Schedule won't allow you to import revenue and the POST Revenue endpoint will return 409 and an error message "No opening day on date: {date} in department: {departmentID}". In that case, import for that day should be skipped.`}</p>
    <h3 {...{
      "id": "exclude-tax-from-revenue-data"
    }}>{`Exclude tax from revenue data`}</h3>
    <p>{`You need to consider whether the revenue should be including or excluding tax, or make a toggle so the client can decide themselves. `}</p>
    <p>{`You may also consider if there are any other sales data that should be excluded e.g. service charges, gift cards, charity fees, tips.`}</p>
    <h3 {...{
      "id": "import-frequency"
    }}>{`Import frequency`}</h3>
    <p>{`It is up to you to decide how often you will update the revenue. You need to be aware that we only store one value for daily revenue, so for days back in time you can only set one value. If revenue of past days in your system is likely to change due to, for instance, payment processing times, you should consider importing revenue for several past days every day to ensure that previously imported values get corrected.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      